import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  GET_ALL_REFUELS_REQUEST,
  GET_ALL_REFUELS_SUCCESS,
  GET_ALL_REFUELS_FAILURE,
  ADD_REFUEL_REQUEST,
  ADD_REFUEL_SUCCESS,
  ADD_REFUEL_FAILURE,
  DELETE_REFUEL_REQUEST,
  DELETE_REFUEL_SUCCESS,
  DELETE_REFUEL_FAILURE,
  IMPORT_REFUELS_REQUEST,
  IMPORT_REFUELS_SUCCESS,
  IMPORT_REFUELS_FAILURE,
  GET_TANKAGE_LIST_REQUEST,
  GET_TANKAGE_LIST_SUCCESS,
  GET_TANKAGE_LIST_FAILURE,
  GET_TANKAGE_SINGLE_VEHICLE_REQUEST,
  GET_TANKAGE_SINGLE_VEHICLE_SUCCESS,
  GET_TANKAGE_SINGLE_VEHICLE_FAILURE,
  UPDATE_TANKAGE_REQUEST,
  UPDATE_TANKAGE_SUCCESS,
  UPDATE_TANKAGE_FAILURE
} from 'constants/ActionTypes';
import { showMessage } from 'actions/Global';
import { API_URL } from '../constants/globalConstants';
import axios from 'axios';

////////////! GET ALL REFUELS BY VEHICLE //////////////////

export function* watcherGetAllRefuelsByVehicle() {
  yield takeEvery(GET_ALL_REFUELS_REQUEST, workerGetAllRefuelsByVehicle);
}

const getAllRefuelsByVehicle = async (
  token,
  { vehicleId, startDate, endDate }
) => {
  console.log('======SAGA=======');
  console.log(vehicleId);
  console.log('======================');
  let url = `${API_URL}refuels?`;

  if (vehicleId) url += 'vehicleId=' + vehicleId + '&';
  if (startDate && endDate)
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetAllRefuelsByVehicle(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAllRefuelsByVehicle, token, action.payload);
    const refuelsList = response.data;

    yield put({ type: GET_ALL_REFUELS_SUCCESS, refuelsList });
  } catch (error) {
    yield put({ type: GET_ALL_REFUELS_FAILURE, error });
  }
}

////////////! ADD REFUEL //////////////////

export function* watcherAddRefuel() {
  yield takeEvery(ADD_REFUEL_REQUEST, workerAddRefuel);
}

const addRefuel = async (token, refuel) => {
  console.log(refuel);

  let url = `${API_URL}refuels`;
  return axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      vehicleId: refuel.vehicleId,
      startDeviceId: refuel.startDeviceId,
      startDeviceDataId: refuel.startDeviceDataId,
      endDeviceId: refuel.endDeviceId,
      endDeviceDataId: refuel.endDeviceDataId
    },
    url: url
  });
};

function* workerAddRefuel(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addRefuel, token, action.payload);
    const refuelAdded = response.data;

    yield put({ type: ADD_REFUEL_SUCCESS, refuelAdded });
  } catch (error) {
    yield put({ type: ADD_REFUEL_FAILURE, error });
  }
}
//--------------------------------------------------------------------

//-----------------------------DELETE REFUEL-----------------------------
export function* watcherDeleteRefuel() {
  yield takeEvery(DELETE_REFUEL_REQUEST, workerDeleteRefuel);
}

const deleteRefuel = async (token, { refuelId }) => {
  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}refuels/` + refuelId
  });
};

function* workerDeleteRefuel(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(deleteRefuel, token, action.payload);
    const refuelDeleted = { id: action.payload.refuelId };

    yield put({ type: DELETE_REFUEL_SUCCESS, refuelDeleted });
  } catch (error) {
    yield put({ type: DELETE_REFUEL_FAILURE, error });
  }
}

////////////! IMPORT REFUELS //////////////////

export function* watcherImportRefuels() {
  yield takeEvery(IMPORT_REFUELS_REQUEST, workerImportRefuels);
}

const importRefuels = async (token, infos) => {
  console.log(infos);
  return axios({
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: infos,
    url: `${API_URL}calibration_refuels`
  });
};

function* workerImportRefuels(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(importRefuels, token, action.payload);
    const refuelsImported = response.data;

    yield put({ type: IMPORT_REFUELS_SUCCESS, refuelsImported });
  } catch (error) {
    yield put({ type: IMPORT_REFUELS_FAILURE, error });
  }
}

////////////! GET TANKAGE LIST //////////////////

export function* watcherGetTankageList() {
  yield takeEvery(GET_TANKAGE_LIST_REQUEST, workerGetTankageList);
}

const getTankageList = async (token, { customerId, status }) => {
  console.log('======SAGA=======');
  console.log(customerId, status);
  console.log('======================');
  let url = `${API_URL}calibration_refuels?customerId=${customerId}`;

  if (status) url += '&status=' + status + '&';

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetTankageList(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTankageList, token, action.payload);
    const tankageList = response.data;

    yield put({ type: GET_TANKAGE_LIST_SUCCESS, tankageList });
  } catch (error) {
    yield put({ type: GET_TANKAGE_LIST_FAILURE, error });
  }
}

////////////! GET TANKAGE SINGLE VEHICLE //////////////////

export function* watcherGetTankageSingleVehicle() {
  yield takeEvery(
    GET_TANKAGE_SINGLE_VEHICLE_REQUEST,
    workerGetTankageSingleVehicle
  );
}

const getTankageSingleVehicle = async (
  token,
  { customerId, vehicleId, status, startDate, endDate }
) => {
  console.log('======SAGA=======');
  console.log(customerId, vehicleId, status, startDate, endDate);
  console.log('======================');
  let url = `${API_URL}calibration_refuels?customerId=${customerId}`;

  if (vehicleId) url += '&vehicleId=' + vehicleId + '&';
  if (status) url += '&status=' + status + '&';
  if (startDate && endDate) {
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  }

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetTankageSingleVehicle(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTankageSingleVehicle, token, action.payload);
    const tankageSingleVehicle = response.data;

    yield put({
      type: GET_TANKAGE_SINGLE_VEHICLE_SUCCESS,
      tankageSingleVehicle
    });
  } catch (error) {
    yield put({ type: GET_TANKAGE_SINGLE_VEHICLE_FAILURE, error });
  }
}

////////////! UPDATE TANKAGE //////////////////

export function* watcherUpdateTankage() {
  yield takeEvery(UPDATE_TANKAGE_REQUEST, workerUpdateTankage);
}

const updateTankage = async (token, { tankageId, confirmed }) => {
  console.log('======SAGA=======');
  console.log(tankageId, confirmed);
  console.log('======================');
  let url = `${API_URL}calibration_refuels/${tankageId}?confirmed=${confirmed}`;

  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerUpdateTankage(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateTankage, token, action.payload);
    const tankageUpdated = response.data;

    yield put({
      type: UPDATE_TANKAGE_SUCCESS,
      tankageUpdated
    });
  } catch (error) {
    yield put({ type: UPDATE_TANKAGE_FAILURE, error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetAllRefuelsByVehicle),
    fork(watcherAddRefuel),
    fork(watcherDeleteRefuel),
    fork(watcherImportRefuels),
    fork(watcherGetTankageList),
    fork(watcherGetTankageSingleVehicle),
    fork(watcherUpdateTankage)
  ]);
}
