import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getVehiclesHeader } from 'actions/Vehicles';
import { openDetailVehicleModal } from 'actions/Global';
import { history } from '../../store';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER
} from 'constants/ActionTypes';
import UserInfo from 'components/UserInfo';
// import MailNotification from '../MailNotification/index';
// import AppNotification from '../AppNotification/index';
// import CardHeader from 'components/dashboard/Common/CardHeader/index';
import { switchLanguage, toggleCollapsedNav } from 'actions/Setting';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
//import LanguageSwitcher from 'components/LanguageSwitcher/index';
import Menu from 'components/TopNav/Menu';
//import UserInfoPopup from 'components/UserInfo/UserInfoPopup';
import GlobalVehicleSearchInput from '../GlobalVehicleSearchInput';
import _ from 'lodash';

const styles = theme => ({
  selectFormControl: {
    width: '10%'
  },
  fontColorInput: {
    color: theme.palette.primary.main,
    '& input': {
      font: theme.palette.primary.main
    }
  },
  iconColor: {
    color: theme.palette.primary.light
  },
  shadowHeader: {
    boxShadow: '0px 2px 6px #00000029'
  },
  toolbarHeight: {
    minHeight: 50
  },
  searchInput: {
    width: '35rem'
  },
  underline: {
    '&:before': {
      borderBottom: `2px solid ${theme.palette.secondary.main}`
    },
    '&:hover': {
      '&:before': {
        borderBottom: `2px solid ${theme.palette.primary.light} !important`
      }
    }
  }
});

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      vehicles: []
    };

    this.offsetVehicle = 0;
    this.limitVehicle = 30;
    this.patternVehicle = '';
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let result = {
      vehicles: nextProps.vehicles.vehiclesHeader
    };

    // if (
    //   nextProps.vehicles.vehiclesHeader !== undefined &&
    //   nextProps.vehicles.vehiclesHeader.length > 0 &&
    //   nextProps.vehicles.fetching === false
    // ) {
    //   result['vehicles'] = nextProps.vehicles.vehiclesHeader.reduce(
    //     (acc, current) => {
    //       const x = acc.find(item => item.id === current.id);
    //       if (!x) {
    //         return acc.concat([current]);
    //       } else {
    //         return acc;
    //       }
    //     },
    //     prevState.vehicles
    //   );
    // }

    return result;
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.global.selectedCustomer !== prevProps.selectedCustomer) {
  //     this.setState({ vehicles: [] });
  //   }
  // }

  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification
    });
  };
  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification
    });
  };
  onLangSwitcherSelect = event => {
    this.setState({
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget
    });
  };
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox
    });
  };
  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps
    });
  };
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    });
  };
  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false
    });
  };
  onToggleCollapsedNav = e => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value
    });
  }

  onInputVehicleChange = _.debounce(e => {
    if (this.patternVehicle !== e || this.offsetVehicle !== 0) {
      this.patternVehicle = e;
      this.offsetVehicle = 0;
      if (this.props.global.selectedCustomer && this.patternVehicle !== '') {
        this.props.getVehiclesHeader({
          customerId: this.props.global.selectedCustomer.id,
          offset: this.offsetVehicle,
          limit: this.limitVehicle,
          pattern: this.patternVehicle
        });
      }
    }
  }, 500);

  onMenuVehicleScrollToBottom = e => {
    console.log('onMenuVehicleScrollToBottom', e);
    this.offsetVehicle += this.limitVehicle;
    this.props.getVehiclesHeader({
      customerId: this.props.global.selectedCustomer.id,
      offset: this.offsetVehicle,
      limit: this.limitVehicle,
      pattern: this.patternVehicle
    });
  };

  selectVehicle = e => {
    this.props.openDetailVehicleModal({ vehicle: e });
  };

  Apps = () => {
    return (
      <ul className="jr-list jr-list-half">
        <li className="jr-list-item">
          <Link className="jr-list-link" rel="keep-params" to="/calendar/basic">
            <i className="zmdi zmdi-calendar zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.calendar.basic" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" rel="keep-params" to="/to-do">
            <i className="zmdi zmdi-check-square zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.appModule.toDo" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" rel="keep-params" to="/mail">
            <i className="zmdi zmdi-email zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.appModule.mail" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" rel="keep-params" to="/chat">
            <i className="zmdi zmdi-comment zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.appModule.chat" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" rel="keep-params" to="/contact">
            <i className="zmdi zmdi-account-box zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.appModule.contact" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" rel="keep-params" to="/">
            <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw" />
            <span className="jr-list-text">Add New</span>
          </Link>
        </li>
      </ul>
    );
  };

  render() {
    const classes = this.props.classes;
    let icon = (
      <SearchIcon
        className={classes.colorPrimaryLight}
        style={{
          width: '1.8rem',
          height: '1.8rem',
          color: this.props.theme.palette.primary.light
        }}
      />
    );
    const {
      drawerType,
      locale,
      navigationStyle,
      horizontalNavPosition
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-block d-xl-none'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'd-block'
      : 'd-none';
    return (
      <AppBar
        className={`app-main-header ${classes.shadowHeader} ${
          navigationStyle === HORIZONTAL_NAVIGATION &&
          horizontalNavPosition === BELOW_THE_HEADER
            ? 'app-main-header-top'
            : ''
        }`}
        elevation={0}>
        <Toolbar
          className={`app-toolbar ${classes.toolbarHeight}`}
          disableGutters={false}>
          {navigationStyle === HORIZONTAL_NAVIGATION ? (
            <div
              className="d-block d-md-none pointer mr-3"
              onClick={this.onToggleCollapsedNav}>
              <span className="jr-menu-icon">
                <span className="menu-icon" />
              </span>
            </div>
          ) : (
            <IconButton
              className={`jr-menu-icon mr-3 ${drawerStyle}`}
              aria-label="Menu"
              onClick={this.onToggleCollapsedNav}>
              <span className="menu-icon" />
            </IconButton>
          )}

          <span className="app-logo mr-2 d-none d-sm-block">
            <img
              src={require('assets/images/logo-alert-gasoil-2.svg')}
              alt="Alert Gasoil Logo"
              title="Alert Gasoil"
              onClick={() => history.push('/')}
            />
          </span>

          {navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === INSIDE_THE_HEADER && <Menu />}

          <div
            className="divider-header"
            style={{ marginRight: '2rem', marginLeft: '2rem' }}
          />

          <span className={classes.searchInput}>
            <GlobalVehicleSearchInput
              variant={'standard'}
              placeholder
              valueChanged={this.selectVehicle}
            />
          </span>

          <div className="ml-auto">
            <div className="d-flex align-items-center">
              <div className="divider-header" style={{ marginRight: '2rem' }} />
              <div style={{ height: '3rem' }}>
                <UserInfo />
              </div>

              {/* <div className="divider-header" style={{ marginRight: '2rem' }} /> */}
            </div>
          </div>
          {/* <ul className="header-notifications list-inline ml-auto">
            <li className="list-inline-item">
              <span className="app-notification-menu">
                <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg" />
                <span className="jr-font-weight-normal">Mon compte</span>
              </span>
            </li> */}
          {/* <li className="d-inline-block d-lg-none list-inline-item">
              <Dropdown
                className="quick-menu nav-searchbox"
                isOpen={this.state.searchBox}
                toggle={this.onSearchBoxSelect.bind(this)}>
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-search zmdi-hc-fw" />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="p-0">
                  <SearchBox
                    styleName="search-dropdown"
                    placeholder=""
                    onChange={this.updateSearchText.bind(this)}
                    value={this.state.searchText}
                  />
                </DropdownMenu>
              </Dropdown>
            </li> */}
          {/* <li className="list-inline-item">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.langSwitcher}
                toggle={this.onLangSwitcherSelect.bind(this)}>
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className={`flag flag-24 flag-${locale.icon}`} />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher
                    switchLanguage={this.props.switchLanguage}
                    handleRequestClose={this.handleRequestClose}
                  />
                </DropdownMenu>
              </Dropdown>
            </li> */}
          {/* <li className="list-inline-item app-tour">
            <Dropdown
              className="quick-menu"
              isOpen={this.state.appNotification}
              toggle={this.onAppNotificationSelect.bind(this)}>
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown">
                <IconButton className="icon-btn">
                  <i
                    className={`zmdi zmdi-notifications-none icon-alert animated infinite wobble ${classes.iconColor}`}
                  />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right>
                <CardHeader
                  styleName="align-items-center"
                  heading={<IntlMessages id="appNotification.title" />}
                />
                <AppNotification />
              </DropdownMenu>
            </Dropdown>
          </li> */}
          {/* <li className="list-inline-item mail-tour">
            <Dropdown
              className="quick-menu"
              isOpen={this.state.mailNotification}
              toggle={this.onMailNotificationSelect.bind(this)}>
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown">
                <IconButton className="icon-btn">
                  <i
                    className={`zmdi zmdi-comment-alt-text zmdi-hc-fw ${classes.iconColor}`}
                  />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right>
                <CardHeader
                  styleName="align-items-center"
                  heading={<IntlMessages id="mailNotification.title" />}
                />
                <MailNotification />
              </DropdownMenu>
            </Dropdown>
          </li> */}

          {/* {navigationStyle === HORIZONTAL_NAVIGATION && (
              <li className="list-inline-item user-nav">
                <Dropdown
                  className="quick-menu"
                  isOpen={this.state.userInfo}
                  toggle={this.onUserInfoSelect.bind(this)}>
                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">
                    <IconButton className="icon-btn size-30">
                      <Avatar
                        alt="..."
                        src={'https://via.placeholder.com/150x150'}
                        className="size-30"
                      />
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right>
                    <UserInfoPopup />
                  </DropdownMenu>
                </Dropdown>
              </li>
            )}
          </ul> */}

          <div className="ellipse-shape" />
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = ({ settings, vehicles, global }) => {
  const {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition
  } = settings;
  return {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition,
    vehicles,
    global
  };
};

//export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, switchLanguage})(Header));
export default withRouter(
  connect(mapStateToProps, {
    toggleCollapsedNav,
    switchLanguage,
    getVehiclesHeader,
    openDetailVehicleModal
  })(
    withStyles(
      theme => ({
        ...styles(theme)
      }),
      { withTheme: true }
    )(injectIntl(Header))
  )
);
