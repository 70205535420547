import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  paper: {
    backgroundColor: 'white',
    padding: 20,
    border: `0.1rem solid ${theme.palette.secondary.main}`,
    padding: theme.spacing(2, 4, 3)
  }
});

const LegalMention = props => {
  const classes = props.classes;
  return (
    <Paper elevation={2} className={classes.paper}>
      <div
        elevation={0}
        className="d-flex justify-content-start align-items-center mb-5">
        <img
          style={{ width: '150px', marginRight: '20px' }}
          src={require('assets/images/logo-alert-gasoil-2.svg')}
          alt="Alert Gasoil logo"
          title="alert gasoil"
        />
        <h3 id="transition-modal-title">Mentions légales</h3>
      </div>
      <p id="transition-modal-description">
        Sint ad velit occaecat minim eiusmod minim mollit elit quis. Et ex
        occaecat in magna culpa mollit reprehenderit et exercitation tempor
        laboris qui reprehenderit ullamco. Fugiat reprehenderit minim in minim
        laborum Lorem laboris adipisicing eu commodo. Cillum sit eiusmod
        incididunt esse ullamco voluptate eiusmod amet dolore amet id sunt.
        Eiusmod in ut ad tempor ea irure ea dolore. Deserunt minim ipsum minim
        culpa anim exercitation adipisicing occaecat dolor veniam ea proident
        adipisicing. Sint elit amet incididunt aliqua et cillum ipsum
        reprehenderit tempor. Eu aliquip voluptate eiusmod ea culpa laboris
        officia consectetur. Aute minim reprehenderit do est mollit ea commodo
        duis ex ex in irure cupidatat. Nostrud ad ipsum commodo magna. Sunt nisi
        quis pariatur sint mollit consectetur incididunt occaecat laboris
        laborum velit sint voluptate. Labore minim exercitation ea cillum
        exercitation culpa. Fugiat dolore tempor in et sit reprehenderit eiusmod
        do minim exercitation.
      </p>
    </Paper>
  );
};

export default withStyles(
  theme => ({
    ...styles(theme)
  }),
  { withTheme: true }
)(LegalMention);
