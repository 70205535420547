import {
  GET_ALL_REFUELS_FAILURE,
  GET_ALL_REFUELS_REQUEST,
  GET_ALL_REFUELS_SUCCESS,
  ADD_REFUEL_FAILURE,
  ADD_REFUEL_REQUEST,
  ADD_REFUEL_SUCCESS,
  DELETE_REFUEL_FAILURE,
  DELETE_REFUEL_REQUEST,
  DELETE_REFUEL_SUCCESS,
  IMPORT_REFUELS_REQUEST,
  IMPORT_REFUELS_SUCCESS,
  IMPORT_REFUELS_FAILURE,
  GET_TANKAGE_LIST_REQUEST,
  GET_TANKAGE_LIST_SUCCESS,
  GET_TANKAGE_LIST_FAILURE,
  GET_TANKAGE_SINGLE_VEHICLE_REQUEST,
  GET_TANKAGE_SINGLE_VEHICLE_SUCCESS,
  GET_TANKAGE_SINGLE_VEHICLE_FAILURE,
  UPDATE_TANKAGE_REQUEST,
  UPDATE_TANKAGE_SUCCESS,
  UPDATE_TANKAGE_FAILURE
} from '../constants/ActionTypes';

const INIT_STATE = {
  refuelsListFetching: false,
  refuelsList: [],
  refuelsImported: null,
  tankageList: null,
  fetchingTankageList: false,
  tankageSingleVehicle: null,
  fetchingTankageSingleVehicle: false,
  tankageUpdated: null,
  fetchingTankageUpdated: false,
  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //? GET ALL REFUELS //
    case GET_ALL_REFUELS_REQUEST: {
      return {
        ...state,
        refuelsListFetching: true,
        error: null
      };
    }
    case GET_ALL_REFUELS_SUCCESS:
      return {
        ...state,
        refuelsListFetching: false,
        refuelsList: action.refuelsList
      };
    case GET_ALL_REFUELS_FAILURE:
      return { ...state, refuelsListFetching: false, error: action.error };

    //? ADD REFUEL//
    case ADD_REFUEL_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case ADD_REFUEL_SUCCESS:
      return { ...state, fetching: false, refuelAdded: action.refuelAdded };
    case ADD_REFUEL_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? DELETE REFUEL//
    case DELETE_REFUEL_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case DELETE_REFUEL_SUCCESS:
      return { ...state, fetching: false, refuelDeleted: action.refuelDeleted };
    case DELETE_REFUEL_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? IMPORT REFUELS //
    case IMPORT_REFUELS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case IMPORT_REFUELS_SUCCESS:
      return {
        ...state,
        fetching: false,
        refuelsImported: action.refuelsImported
      };
    case IMPORT_REFUELS_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET TANKAGE LIST //
    case GET_TANKAGE_LIST_REQUEST: {
      return {
        ...state,
        fetchingTankageList: true,
        error: null
      };
    }
    case GET_TANKAGE_LIST_SUCCESS:
      return {
        ...state,
        fetchingTankageList: false,
        tankageList: action.tankageList
      };
    case GET_TANKAGE_LIST_FAILURE:
      return { ...state, fetchingTankageList: false, error: action.error };

    //? GET TANKAGE SINGLE VEHICLE //
    case GET_TANKAGE_SINGLE_VEHICLE_REQUEST: {
      return {
        ...state,
        fetchingTankageSingleVehicle: true,
        error: null
      };
    }
    case GET_TANKAGE_SINGLE_VEHICLE_SUCCESS:
      return {
        ...state,
        fetchingTankageSingleVehicle: false,
        tankageSingleVehicle: action.tankageSingleVehicle
      };
    case GET_TANKAGE_SINGLE_VEHICLE_FAILURE:
      return {
        ...state,
        fetchingTankageSingleVehicle: false,
        error: action.error
      };

    //? UPDATE TANKAGE //
    case UPDATE_TANKAGE_REQUEST: {
      return {
        ...state,
        fetchingTankageUpdated: true,
        error: null
      };
    }
    case UPDATE_TANKAGE_SUCCESS:
      return {
        ...state,
        fetchingTankageUpdated: false,
        tankageUpdated: action.tankageUpdated
      };
    case UPDATE_TANKAGE_FAILURE:
      return { ...state, fetchingTankageUpdated: false, error: action.error };

    default:
      return state;
  }
};
