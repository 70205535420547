import {
  GLOBAL_FILTER_CHANGE_FIELDS,
  GLOBAL_FILTER_CHANGE_CUSTOMER,
  GLOBAL_FILTER_CHANGE_SITES,
  GLOBAL_FILTER_CHANGE_VEHICLES,
  GLOBAL_FILTER_CHANGE_DRIVERS,
  GLOBAL_FILTER_CHANGE_VEHICLE_GROUPS,
  GLOBAL_FILTER_CHANGE_PERIOD,
  GLOBAL_OPEN_DETAIL_VEHICLE_MODAL,
  GLOBAL_CLOSE_DETAIL_VEHICLE_MODAL,
  GLOBAL_OPEN_COMPARE_STAT_MODAL,
  GLOBAL_CLOSE_COMPARE_STAT_MODAL,
  GLOBAL_OPEN_CONSUMPTION_VEHICLE_MODAL,
  GLOBAL_CLOSE_CONSUMPTION_VEHICLE_MODAL,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  GLOBAL_SEARCH_REQUEST,
  GLOBAL_SEARCH_SUCCESS,
  GLOBAL_SEARCH_FAILURE,
  GET_CURRENCIES_REQUEST,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAILURE,
  GET_MEASURE_UNITS_REQUEST,
  GET_MEASURE_UNITS_SUCCESS,
  GET_MEASURE_UNITS_FAILURE,
  GET_DEFAULT_PAGES_REQUEST,
  GET_DEFAULT_PAGES_SUCCESS,
  GET_DEFAULT_PAGES_FAILURE,
  GET_TREATMENT_REQUESTS_REQUEST,
  GET_TREATMENT_REQUESTS_SUCCESS,
  GET_TREATMENT_REQUESTS_FAILURE,
  GET_TREATMENT_REQUESTS_LEVEL_ENUM_REQUEST,
  GET_TREATMENT_REQUESTS_LEVEL_ENUM_SUCCESS,
  GET_TREATMENT_REQUESTS_LEVEL_ENUM_FAILURE,
  GET_TREATMENT_REQUESTS_TYPE_ENUM_REQUEST,
  GET_TREATMENT_REQUESTS_TYPE_ENUM_SUCCESS,
  GET_TREATMENT_REQUESTS_TYPE_ENUM_FAILURE,
  CREATE_TREATMENT_REQUESTS_REQUEST,
  CREATE_TREATMENT_REQUESTS_SUCCESS,
  CREATE_TREATMENT_REQUESTS_FAILURE
} from 'constants/ActionTypes';

const initialState = {
  selectedCustomer: null,
  selectedSites: null,
  selectedVehicles: null,
  selectedDrivers: null,
  selectedVehicleGroups: null,
  selectedAdaType: null,
  selectedAdaOccurence: null,
  selectedCalibrationType: null,
  selectedPackageActive: null,
  selectedTheftType: null,
  selectedPeriod: null,
  startDate: null,
  endDate: null,
  detailVehicleOpened: null,
  compareStatOpened: null,
  consumptionVehicleOpened: null,
  consumptionDriverOpened: null,
  consumptionSelectedPeriod: null,
  globalSearchFetching: false,
  globalSearchList: null,
  currenciesFetching: false,
  currencies: null,
  measureUnitsFetching: false,
  measureUnits: null,
  defaultPagesFetching: false,
  defaultPages: null,
  treatmentRequestsFetching: false,
  treatmentRequests: null,
  treatmentRequestsLevelEnumFetching: false,
  treatmentRequestsLevelEnum: null,
  treatmentRequestsTypeEnumFetching: false,
  treatmentRequestsTypeEnum: null,
  notification: null,
  showNotificationDate: null
};

const global = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_FILTER_CHANGE_FIELDS:
      let res = {
        ...state
      };

      if (action.payload.customer) {
        if (action.payload.customer.id == '') res['selectedCustomer'] = null;
        else res['selectedCustomer'] = action.payload.customer;
      }
      if (action.payload.sites) res['selectedSites'] = action.payload.sites;
      if (action.payload.vehicles)
        res['selectedVehicles'] = action.payload.vehicles;
      if (action.payload.drivers)
        res['selectedDrivers'] = action.payload.drivers;
      if (action.payload.vehicleGroups)
        res['selectedVehicleGroups'] = action.payload.vehicleGroups;
      if (action.payload.adaType) {
        res['selectedAdaType'] = action.payload.adaType;
      } else if (action.payload.adaType === false)
        res['selectedAdaType'] = null;
      if (action.payload.adaOccurence != null) {
        res['selectedAdaOccurence'] = action.payload.adaOccurence;
      }
      if (action.payload.calibrationType) {
        res['selectedCalibrationType'] = action.payload.calibrationType;
      } else if (action.payload.calibrationType === false)
        res['selectedCalibrationType'] = null;
      if (
        action.payload.packageActive !== null &&
        action.payload.packageActive !== -1
      ) {
        res['selectedPackageActive'] = action.payload.packageActive;
      } else if (action.payload.packageActive === -1) {
        res['selectedPackageActive'] = null;
      }
      if (action.payload.theftType) {
        res['selectedTheftType'] = action.payload.theftType;
      } else if (action.payload.theftType === false)
        res['selectedTheftType'] = null;

      if (action.payload.period) res['selectedPeriod'] = action.payload.period;
      if (action.payload.startDate) res['startDate'] = action.payload.startDate;
      if (action.payload.endDate) res['endDate'] = action.payload.endDate;

      return res;

    case GLOBAL_FILTER_CHANGE_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.payload
      };

    case GLOBAL_FILTER_CHANGE_SITES:
      return {
        ...state,
        selectedSites: action.payload
      };

    case GLOBAL_FILTER_CHANGE_VEHICLES:
      return {
        ...state,
        selectedVehicles: action.payload
      };

    case GLOBAL_FILTER_CHANGE_DRIVERS:
      return {
        ...state,
        selectedDrivers: action.payload
      };

    case GLOBAL_FILTER_CHANGE_VEHICLE_GROUPS:
      return {
        ...state,
        selectedVehicleGroups: action.payload
      };

    case GLOBAL_FILTER_CHANGE_PERIOD:
      return {
        ...state,
        selectedPeriod: action.payload
      };

    case GLOBAL_OPEN_DETAIL_VEHICLE_MODAL:
      return {
        ...state,
        detailVehicleOpened: action.payload
      };

    case GLOBAL_CLOSE_DETAIL_VEHICLE_MODAL:
      return {
        ...state,
        detailVehicleOpened: null
      };

    case GLOBAL_OPEN_COMPARE_STAT_MODAL:
      return {
        ...state,
        compareStatOpened: action.payload
      };

    case GLOBAL_CLOSE_COMPARE_STAT_MODAL:
      return {
        ...state,
        compareStatOpened: null
      };

    case GLOBAL_OPEN_CONSUMPTION_VEHICLE_MODAL:
      return {
        ...state,
        consumptionVehicleOpened: action.payload.vehicle,
        consumptionDriverOpened: action.payload.driver,
        consumptionSelectedPeriod: action.payload.period
      };

    case GLOBAL_CLOSE_CONSUMPTION_VEHICLE_MODAL:
      return {
        ...state,
        consumptionVehicleOpened: null,
        consumptionDriverOpened: null,
        consumptionSelectedPeriod: null
      };

    //? Permet d'afficher et cacher un message de notification
    case SHOW_MESSAGE: {
      return {
        ...state,
        notification: {
          title: action.title,
          message: action.message,
          messageType: action.messageType,
          duration: action.duration,
          position: action.position
        },
        showNotificationDate: new Date().getTime()
      };
    }

    //? GLOBAL SEARCH //
    case GLOBAL_SEARCH_REQUEST: {
      return {
        ...state,
        globalSearchFetching: true,
        error: null
      };
    }
    case GLOBAL_SEARCH_SUCCESS:
      return {
        ...state,
        globalSearchFetching: false,
        globalSearchList: action.globalSearchList
      };
    case GLOBAL_SEARCH_FAILURE:
      return { ...state, globalSearchFetching: false, error: action.error };

    //? GET CURRENCIES //
    case GET_CURRENCIES_REQUEST: {
      return {
        ...state,
        currenciesFetching: true,
        error: null
      };
    }
    case GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        currenciesFetching: false,
        currencies: action.currencies
      };
    case GET_CURRENCIES_FAILURE:
      return { ...state, currenciesFetching: false, error: action.error };

    //? GET MEASURE UNITS //
    case GET_MEASURE_UNITS_REQUEST: {
      return {
        ...state,
        measureUnitsFetching: true,
        error: null
      };
    }
    case GET_MEASURE_UNITS_SUCCESS:
      return {
        ...state,
        measureUnitsFetching: false,
        measureUnits: action.measureUnits
      };
    case GET_MEASURE_UNITS_FAILURE:
      return { ...state, measureUnitsFetching: false, error: action.error };

    //? GET DEFAULT PAGES //
    case GET_DEFAULT_PAGES_REQUEST: {
      return {
        ...state,
        defaultPagesFetching: true,
        error: null
      };
    }
    case GET_DEFAULT_PAGES_SUCCESS:
      return {
        ...state,
        defaultPagesFetching: false,
        defaultPages: action.defaultPages
      };
    case GET_DEFAULT_PAGES_FAILURE:
      return { ...state, defaultPagesFetching: false, error: action.error };

    //? GET TREATMENT REQUESTS //
    case GET_TREATMENT_REQUESTS_REQUEST: {
      return {
        ...state,
        treatmentRequestsFetching: true,
        error: null
      };
    }
    case GET_TREATMENT_REQUESTS_SUCCESS:
      return {
        ...state,
        treatmentRequestsFetching: false,
        treatmentRequests: action.treatmentRequests
      };
    case GET_TREATMENT_REQUESTS_FAILURE:
      return {
        ...state,
        treatmentRequestsFetching: false,
        error: action.error
      };

    //? GET TREATMENT REQUESTS LEVEL ENUM //
    case GET_TREATMENT_REQUESTS_LEVEL_ENUM_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_TREATMENT_REQUESTS_LEVEL_ENUM_SUCCESS:
      return {
        ...state,
        fetching: false,
        treatmentRequestsLevelEnum: action.treatmentRequestsLevelEnum
      };
    case GET_TREATMENT_REQUESTS_LEVEL_ENUM_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    //? GET TREATMENT REQUESTS TYPE ENUM //
    case GET_TREATMENT_REQUESTS_TYPE_ENUM_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_TREATMENT_REQUESTS_TYPE_ENUM_SUCCESS:
      return {
        ...state,
        fetching: false,
        treatmentRequestsTypeEnum: action.treatmentRequestsTypeEnum
      };
    case GET_TREATMENT_REQUESTS_TYPE_ENUM_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    //? CREATE TREATMENT REQUESTS //
    case CREATE_TREATMENT_REQUESTS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case CREATE_TREATMENT_REQUESTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        treatmentRequestCreated: action.treatmentRequestCreated
      };
    case CREATE_TREATMENT_REQUESTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default global;
