import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  GET_ALL_THEFTS_REQUEST,
  GET_ALL_THEFTS_SUCCESS,
  GET_ALL_THEFTS_FAILURE,
  ADD_THEFT_REQUEST,
  ADD_THEFT_SUCCESS,
  ADD_THEFT_FAILURE,
  DELETE_THEFT_REQUEST,
  DELETE_THEFT_SUCCESS,
  DELETE_THEFT_FAILURE
} from 'constants/ActionTypes';
import { showMessage } from 'actions/Global';
import { API_URL } from '../constants/globalConstants';
import axios from 'axios';

////////////! GET ALL THEFTS BY VEHICLE //////////////////

export function* watcherGetAllTheftsByVehicle() {
  yield takeEvery(GET_ALL_THEFTS_REQUEST, workerGetAllTheftsByVehicle);
}

const getAllTheftsByVehicle = async (
  token,
  { customerId, vehicleId, startDate, endDate, theftTypes }
) => {
  let url = `${API_URL}thefts?`;

  if (customerId) url += 'customerId=' + customerId + '&';
  if (vehicleId) url += 'vehicleId=' + vehicleId + '&';
  if (theftTypes) {
    for (let idx in theftTypes) {
      url += 'theftType=' + theftTypes[idx] + '&';
    }
  }
  if (startDate && endDate)
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;

  console.log(url);
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetAllTheftsByVehicle(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAllTheftsByVehicle, token, action.payload);
    const theftsList = response.data;

    yield put({ type: GET_ALL_THEFTS_SUCCESS, theftsList });
  } catch (error) {
    yield put({ type: GET_ALL_THEFTS_FAILURE, error });
  }
}

////////////! ADD THEFT //////////////////

export function* watcherAddTheft() {
  yield takeEvery(ADD_THEFT_REQUEST, workerAddTheft);
}

const addTheft = async (token, theft) => {
  console.log(theft);

  let url = `${API_URL}thefts`;
  return axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      vehicleId: theft.vehicleId,
      startDeviceId: theft.startDeviceId,
      startDeviceDataId: theft.startDeviceDataId,
      endDeviceId: theft.endDeviceId,
      endDeviceDataId: theft.endDeviceDataId
    },
    url: url
  });
};

function* workerAddTheft(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addTheft, token, action.payload);
    const theftAdded = response.data;

    yield put({ type: ADD_THEFT_SUCCESS, theftAdded });
  } catch (error) {
    yield put({ type: ADD_THEFT_FAILURE, error });
  }
}
//--------------------------------------------------------------------

//-----------------------------DELETE THEFT-----------------------------
export function* watcherDeleteTheft() {
  yield takeEvery(DELETE_THEFT_REQUEST, workerDeleteTheft);
}

const deleteTheft = async (token, { theftId }) => {
  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}thefts/` + theftId
  });
};

function* workerDeleteTheft(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(deleteTheft, token, action.payload);
    const theftDeleted = { id: action.payload.theftId };

    yield put({ type: DELETE_THEFT_SUCCESS, theftDeleted });
  } catch (error) {
    yield put({ type: DELETE_THEFT_FAILURE, error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetAllTheftsByVehicle),
    fork(watcherAddTheft),
    fork(watcherDeleteTheft)
  ]);
}
